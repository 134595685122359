<template>
    <div>
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                               <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="项目名称">
                                        <el-input style="width: 200px" size="medium" v-model="xmmc" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="项目性质">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedXmxz" :options="xmxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="项目类型">
                                        <el-cascader size="medium" style="width: 200px" v-model="checkedXmlx" :options="xmlxList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: true }" collapse-tags clearable>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-button type="primary" style="margin-top:19px" size="medium" icon="el-icon-search" @click="currentPage = 1;search();">查询</el-button>
                                    <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清空</el-button>
                                </el-form>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row style="margin:0" class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">
                                <span class="fright">
                                </span>
                                <h3 class="tit">水运列表</h3>
                            </div>
                            <div class="box-content">
                                <el-table @row-dblclick="openDetailsView" :data="tableData" size="small " :height="tableHeight" border :default-sort="{ prop: 'XH', order: 'ascending' }" style="width: 100%">
                                    <el-table-column fixed prop="" label="操作" :width="130" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <span class="newicon iconsyes" @click="openDetailsView(scope.row)"><i class="el-icon-info icons"></i>详情</span>
                                            <span class="newicon iconsyes" @click="openDetailsEdit(scope.row)"><i class="el-icon-edit-outline icons"></i>编辑</span>
                                            <!-- <i class="el-icon-postcard icons iconsyes" @click="openDetailsView(scope.row)"></i> -->
                                            <!-- <i class="el-icon-edit-outline icons iconsyes" style="margin-right:0" @click="openDetailsEdit(scope.row)"></i> -->
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="xh" label="序号" show-overflow-tooltip :width="70" sortable></el-table-column>
                                    <el-table-column prop="xmlx" label="项目类型" :width="100" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="xmxz" label="项目性质" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="xmmc" label="项目名称" show-overflow-tooltip :width="350" sortable></el-table-column>
                                    <el-table-column prop="xmbh" label="项目编号" :width="150" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="jsgm" label="建设规模" :width="180" show-overflow-tooltip></el-table-column>
                                    <el-table-column prop="sswtz" label="“十四五”投资（亿元）" :width="180" sortable show-overflow-tooltip></el-table-column>
                                    <el-table-column label="工期">
                                        <el-table-column prop="kgn" label="开工年" width="100" align="center"></el-table-column>
                                        <el-table-column prop="wgn" label="完工年" width="100" align="center"></el-table-column>
                                    </el-table-column>
                                    <el-table-column label="可研报告">
                                        <el-table-column prop="kybgbzwcsj" label="报告编制完成时间" width="140" align="center"></el-table-column>
                                        <el-table-column prop="kybgbzbtsj" label="报厅时间" width="100" align="center"></el-table-column>
                                        <el-table-column prop="kybgbzbtwh" label="报厅文号" width="" align="center"></el-table-column>
                                        <el-table-column prop="kybgbzcswcsj" label="初审完成时间" width="120" align="center"></el-table-column>
                                        <el-table-column prop="kybgbzbbsj" label="报部时间" width="100" align="center"></el-table-column>
                                        <el-table-column prop="kybgbzbbwh" label="报部文号" width="" align="center"></el-table-column>
                                        <el-table-column prop="kybgbzcssj" label="审查时间" width="100" align="center"></el-table-column>
                                        <el-table-column prop="kybgbzpfsj" label="批复时间" width="100" align="center"></el-table-column>
                                        <el-table-column prop="kybgbzpfwh" label="批复文号" width="" align="center"></el-table-column>
                                        <el-table-column prop="kybgbztz" label="投资" width="" align="center"></el-table-column>
                                    </el-table-column>
                                    <el-table-column label="初步设计">
                                        <el-table-column prop="cbsjbgbzwcsj" label="报告编制完成时间" width="140" align="center"></el-table-column>
                                        <el-table-column prop="cbsjbtsj" label="报厅时间" width="100" align="center"></el-table-column>
                                        <el-table-column prop="cbsjbtwh" label="报厅文号" width="" align="center"></el-table-column>
                                        <el-table-column prop="cbsjscsj" label="审查时间" width="100" align="center"></el-table-column>
                                        <el-table-column prop="cbsjpfsj" label="批复时间" width="100" align="center"></el-table-column>
                                        <el-table-column prop="cbsjpfwh" label="批复文号" width="" align="center"></el-table-column>
                                        <el-table-column prop="cbsjtz" label="投资" width="" align="center"></el-table-column>
                                    </el-table-column>
                                    <el-table-column label="总投资">
                                        <el-table-column prop="ztzsghtqdsj" label="施工合同签订时间" width="140" align="center"></el-table-column>
                                        <el-table-column prop="ztzjzhtqdsj" label="报厅时间" width="100" align="center"></el-table-column>
                                        <el-table-column prop="ztzbtwh" label="报厅文号" width="" align="center"></el-table-column>
                                        <el-table-column prop="ztzbbsj" label="报部时间" width="100" align="center"></el-table-column>
                                        <el-table-column prop="ztzbbwh" label="报部文号" width="" align="center"></el-table-column>
                                        <el-table-column prop="ztzpfsj" label="批复时间" width="100" align="center"></el-table-column>
                                        <el-table-column prop="ztzpfwh" label="批复文号" width="" align="center"></el-table-column>
                                        <el-table-column prop="ztztz" label="投资" width="" align="center"></el-table-column>
                                    </el-table-column>
                                </el-table>
                                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[30, 50, 100, 400]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                                </el-pagination>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
        <el-drawer :title="details.xmmc" :append-to-body="true" :visible.sync="qqDrawer" size="80%">
            <el-main>
                <div class="mains" :class="{'isviewinfo':!isview}" v-loading="vloading" element-loading-text="正在上传中，请等待" element-loading-spinner="el-icon-loading">
                    <div class="title" @click="hides.jbxx = !hides.jbxx" style="margin-top:0!important">
                        <span>基本信息</span>
                        <i :class="!hides.jbxx?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                    </div>
                    <div class="boxs" :class="{boxshide:hides.jbxx}">
                        <el-row style="margin-bottom:10px">
                            <el-col :span="8" class="flexs-m">
                                <div class="name">项目类型</div>
                                <div class="value">{{details.xmlx}}</div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">项目性质</div>
                                <div class="value">{{details.xmxz}}</div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">十四五投资（亿元）</div>
                                <div class="value">{{details.sswtz}}</div>
                            </el-col>
                            <el-col :span="8" class="flexs-m"></el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px">
                            <el-col :span="24" class="flexs-m">
                                <div class="name">建设规模</div>
                                <div class="value">{{details.jsgm}}</div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="title" @click="hides.gq = !hides.gq">
                        <span>工期</span>
                        <i :class="!hides.gq?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                    </div>
                    <div class="boxs" :class="{boxshide:hides.gq}">
                        <el-row style="margin-bottom:10px">
                            <el-col :span="8" class="flexs-m">
                                <div class="name">开工年</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.kgn" style="width: 100%;height:32px"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">完工年</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.wgn" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name name3"></div>
                                <div class="value"></div>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="title" @click="hides.kybg = !hides.kybg">
                        <span>可研报告</span>
                        <i :class="!hides.kybg?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                    </div>
                    <div class="boxs" :class="{boxshide:hides.kybg}">
                        <el-row style="margin-bottom:10px">
                            <el-col :span="8" class="flexs-m">
                                <div class="name">报告编制完成时间</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.kybgbzwcsj" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">报厅时间</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.kybgbzbtsj" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">报厅文号</div>
                                <div class="value">
                                    <el-input :readonly="!isview" placeholder="报厅文号" v-model="details.kybgbzbtwh"></el-input>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px">
                            <el-col :span="24" class="flexs-m">
                                <div class="name">
                                    可研报告附件
                                </div>
                                <div class="value value1">
                                    <div class="name1" v-if="isview">
                                        <label for="fileInputKybgwj" class=" el-button  el-button--primary el-button--small cursor">
                                            <i aria-hidden="true">上传</i>
                                        </label>
                                        <input type="file" v-show="false" name="file" ref="file" id="fileInputKybgwj" multiple="multiple" @change="fileSubmit($event,'kybgwj')" />
                                    </div>
                                    <div class="files">
                                        <div class="wj" v-for="(item,index) in details.kybgwj" :key="index">
                                            <el-link :href="baseUrl+item" type="primary">{{getFilesName(item)}}</el-link>
                                            <el-link v-if="isview" class="fj-del" icon="el-icon-delete" @click="unsetFiles(details.kybgwj,index)"></el-link>
                                            <span v-if="!isview" style="color:#66b1ff;margin:0 10px 0 3px;font-weight: 700;">;</span>

                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row style="margin-bottom:10px">
                            <el-col :span="8" class="flexs-m">
                                <div class="name">初审完成时间</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.kybgbzcswcsj" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">报部时间</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.kybgbzbbsj" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">报部文号</div>
                                <div class="value">
                                    <el-input :readonly="!isview" placeholder="报部文号" v-model="details.kybgbzbbwh"></el-input>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px">
                            <el-col :span="24" class="flexs-m">
                                <div class="name">
                                    评审附件
                                </div>
                                <div class="value value1">
                                    <div class="name1" v-if="isview">
                                        <label for="fileInputkybbcswj" class=" el-button  el-button--primary el-button--small cursor">
                                            <i aria-hidden="true">上传</i>
                                        </label>
                                        <input type="file" v-show="false" name="file" ref="file" id="fileInputkybbcswj" multiple="multiple" @change="fileSubmit($event,'kybbcswj')" />
                                    </div>
                                    <div class="files">
                                        <div class="wj" v-for="(item,index) in details.kybbcswj" :key="index">
                                            <el-link :href="baseUrl+item" type="primary">{{getFilesName(item)}}</el-link>
                                            <el-link v-if="isview" class="fj-del" icon="el-icon-delete" @click="unsetFiles(details.kybbcswj,index)"></el-link>
                                            <span v-if="!isview" style="color:#66b1ff;margin:0 10px 0 3px;font-weight: 700;">;</span>

                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px">
                            <el-col :span="8" class="flexs-m">
                                <div class="name">审查时间</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.kybgbzcssj" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">批复时间</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.kybgbzpfsj" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">报厅文号</div>
                                <div class="value">
                                    <el-input :readonly="!isview" placeholder="报厅文号" v-model="details.kybgbzpfwh"></el-input>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px">
                            <el-col :span="24" class="flexs-m">
                                <div class="name">
                                    批复附件
                                </div>
                                <div class="value value1">
                                    <div class="name1" v-if="isview">
                                        <label for="fileInputkybbpfwj" class=" el-button  el-button--primary el-button--small cursor">
                                            <i aria-hidden="true">上传</i>
                                        </label>
                                        <input type="file" v-show="false" name="file" ref="file" id="fileInputkybbpfwj" multiple="multiple" @change="fileSubmit($event,'kybbpfwj')" />
                                    </div>
                                    <div class="files">
                                        <div class="wj" v-for="(item,index) in details.kybbpfwj" :key="index">
                                            <el-link :href="baseUrl+item" type="primary">{{getFilesName(item)}}</el-link>
                                            <el-link v-if="isview" class="fj-del" icon="el-icon-delete" @click="unsetFiles(details.kybbpfwj,index)"></el-link>
                                            <span v-if="!isview" style="color:#66b1ff;margin:0 10px 0 3px;font-weight: 700;">;</span>

                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px">
                            <el-col :span="8" class="flexs-m">
                                <div class="name">投资（亿元）</div>
                                <div class="value">
                                    <el-input :readonly="!isview" placeholder="投资（亿元）" type="number" v-model="details.kybgbztz"></el-input>
                                </div>
                            </el-col>
                            <el-col :span="16" class="flexs-m">
                                <div class="name name3"></div>
                                <div class="value"></div>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="title" @click="hides.cbsj = !hides.cbsj">
                        <span>初步设计</span>
                        <i :class="!hides.cbsj?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                    </div>
                    <div class="boxs" :class="{boxshide:hides.cbsj}">
                        <el-row style="margin-bottom:10px">
                            <el-col :span="8" class="flexs-m">
                                <div class="name">报告编制完成时间</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.cbsjbgbzwcsj" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">报厅时间</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.cbsjbtsj" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">报厅文号</div>
                                <div class="value">
                                    <el-input :readonly="!isview" placeholder="报厅文号" v-model="details.cbsjbtwh"></el-input>
                                </div>
                            </el-col>

                        </el-row>
                        <el-row style="margin-bottom:10px">
                            <el-col :span="24" class="flexs-m">
                                <div class="name">
                                    初步设计附件
                                </div>
                                <div class="value value1">
                                    <div class="name1" v-if="isview">
                                        <label for="fileInputcbsjwj" class=" el-button  el-button--primary el-button--small cursor">
                                            <i aria-hidden="true">上传</i>
                                        </label>
                                        <input type="file" v-show="false" name="file" ref="file" id="fileInputcbsjwj" multiple="multiple" @change="fileSubmit($event,'cbsjwj')" />
                                    </div>
                                    <div class="files">
                                        <div class="wj" v-for="(item,index) in details.cbsjwj" :key="index">
                                            <el-link :href="baseUrl+item" type="primary">{{getFilesName(item)}}</el-link>
                                            <el-link v-if="isview" class="fj-del" icon="el-icon-delete" @click="unsetFiles(details.cbsjwj,index)"></el-link>
                                            <span v-if="!isview" style="color:#66b1ff;margin:0 10px 0 3px;font-weight: 700;">;</span>

                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px">
                            <el-col :span="8" class="flexs-m">
                                <div class="name">审查时间</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.cbsjscsj" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">批复时间</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.cbsjpfsj" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">报厅文号</div>
                                <div class="value">
                                    <el-input :readonly="!isview" placeholder="报厅文号" v-model="details.cbsjpfwh"></el-input>
                                </div>
                            </el-col>

                        </el-row>
                        <el-row style="margin-bottom:10px">
                            <el-col :span="24" class="flexs-m">
                                <div class="name">
                                    评审附件
                                </div>
                                <div class="value value1">
                                    <div class="name1" v-if="isview">
                                        <label for="fileInputcbsjpfwj" class=" el-button  el-button--primary el-button--small cursor">
                                            <i aria-hidden="true">上传</i>
                                        </label>
                                        <input type="file" v-show="false" name="file" ref="file" id="fileInputcbsjpfwj" multiple="multiple" @change="fileSubmit($event,'cbsjpfwj')" />
                                    </div>
                                    <div class="files">
                                        <div class="wj" v-for="(item,index) in details.cbsjpfwj" :key="index">
                                            <el-link :href="baseUrl+item" type="primary">{{getFilesName(item)}}</el-link>
                                            <el-link v-if="isview" class="fj-del" icon="el-icon-delete" @click="unsetFiles(details.cbsjpfwj,index)"></el-link>
                                            <span v-if="!isview" style="color:#66b1ff;margin:0 10px 0 3px;font-weight: 700;">;</span>

                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px">
                            <el-col :span="8" class="flexs-m">
                                <div class="name">投资（亿元）</div>
                                <div class="value">
                                    <el-input :readonly="!isview" placeholder="投资（亿元）" type="number" v-model="details.cbsjtz"></el-input>
                                </div>
                            </el-col>
                            <el-col :span="16" class="flexs-m">
                                <div class="name name3"></div>
                                <div class="value"></div>
                            </el-col>
                        </el-row>
                    </div>

                    <div v-if="details.xmlx == '港口项目' || details.xmlx == '航道项目' || details.xmlx == '航电枢纽'">
                        <div class="title" @click="hides.qzj = !hides.qzj">
                            <span>前置件</span>
                            <i :class="!hides.qzj?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                        </div>
                        <div class="boxs" :class="{boxshide:hides.qzj}">
                            <el-row style="margin-bottom:10px">
                                <el-col :span="8" class="flexs-m" v-if="details.xmlx == '港口项目'">
                                    <div class="name">建设用地</div>
                                    <div class="value">
                                        <el-checkbox v-if="isview" v-model="details.qzjjsyd" value="是">是</el-checkbox>
                                        <span v-else>{{details.qzjjsyd?'是':'否'}}</span>
                                    </div>
                                </el-col>
                                <el-col :span="8" class="flexs-m" v-if="details.xmlx == '航道项目' || details.xmlx == '航电枢纽'">
                                    <div class="name">通航论证</div>
                                    <div class="value">
                                        <el-checkbox v-if="isview" v-model="details.qzjthlz" value="是">是</el-checkbox>
                                        <span v-else>{{details.qzjthlz?'是':'否'}}</span>
                                    </div>
                                </el-col>
                                <el-col :span="8" class="flexs-m">
                                    <div class="name">环境影响</div>
                                    <div class="value">
                                        <el-checkbox v-if="isview" v-model="details.qzjhjyx" value="是">是</el-checkbox>
                                        <span v-else>{{details.qzjhjyx?'是':'否'}}</span>
                                    </div>
                                </el-col>
                                <el-col :span="8" class="flexs-m">
                                    <div class="name">其他要件</div>
                                    <div class="value">
                                        <el-checkbox v-if="isview" v-model="details.qzjqtsj" value="是">是</el-checkbox>
                                        <span v-else>{{details.qzjqtsj?'是':'否'}}</span>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                    <div class="title" @click="hides.ztz = !hides.ztz">
                        <span>总投资</span>
                        <i :class="!hides.ztz?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                    </div>
                    <div class="boxs" :class="{boxshide:hides.ztz}">
                        <el-row style="margin-bottom:10px">
                            <el-col :span="8" class="flexs-m">
                                <div class="name">施工合同签订时间</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.ztzsghtqdsj" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">报厅时间</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.ztzjzhtqdsj" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">报厅文号</div>
                                <div class="value">
                                    <el-input :readonly="!isview" placeholder="报厅文号" v-model="details.ztzbtwh"></el-input>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px">
                            <el-col :span="24" class="flexs-m">
                                <div class="name">
                                    施工合同附件
                                </div>
                                <div class="value value1">
                                    <div class="name1" v-if="isview">
                                        <label for="fileInputztzwj" class=" el-button  el-button--primary el-button--small cursor">
                                            <i aria-hidden="true">上传</i>
                                        </label>
                                        <input type="file" v-show="false" name="file" ref="file" id="fileInputztzwj" multiple="multiple" @change="fileSubmit($event,'ztzwj')" />
                                    </div>
                                    <div class="files">
                                        <div class="wj" v-for="(item,index) in details.ztzwj" :key="index">
                                            <el-link :href="baseUrl+item" type="primary">{{getFilesName(item)}}</el-link>
                                            <el-link v-if="isview" class="fj-del" icon="el-icon-delete" @click="unsetFiles(details.ztzwj,index)"></el-link>
                                            <span v-if="!isview" style="color:#66b1ff;margin:0 10px 0 3px;font-weight: 700;">;</span>
                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px">
                            <el-col :span="8" class="flexs-m">
                                <div class="name">投资（亿元）</div>
                                <div class="value">
                                    <el-input :readonly="!isview" placeholder="投资（亿元）" type="number" v-model="details.ztztz"></el-input>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">报部时间</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.ztzbbsj" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">报部文号</div>
                                <div class="value">
                                    <el-input :readonly="!isview" placeholder="报部文号" v-model="details.ztzbbwh"></el-input>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px">
                            <el-col :span="8" class="flexs-m">
                                <div class="name">批复时间</div>
                                <div class="value">
                                    <el-date-picker type="date" :readonly="!isview" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="details.ztzpfsj" style="width: 100%"></el-date-picker>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name">批复文号</div>
                                <div class="value">
                                    <el-input :readonly="!isview" placeholder="批复文号" v-model="details.ztzpfwh"></el-input>
                                </div>
                            </el-col>
                            <el-col :span="8" class="flexs-m">
                                <div class="name name3"></div>
                                <div class="value"></div>
                            </el-col>
                        </el-row>
                        <el-row style="margin-bottom:10px">
                            <el-col :span="24" class="flexs-m">
                                <div class="name">
                                    批复附件
                                </div>
                                <div class="value value1">
                                    <div class="name1" v-if="isview">
                                        <label for="fileInputztzpfwj" class=" el-button  el-button--primary el-button--small cursor">
                                            <i aria-hidden="true">上传</i>
                                        </label>
                                        <input type="file" v-show="false" name="file" ref="file" id="fileInputztzpfwj" multiple="multiple" @change="fileSubmit($event,'ztzpfwj')" />
                                    </div>
                                    <div class="files">
                                        <div class="wj" v-for="(item,index) in details.ztzpfwj" :key="index">
                                            <el-link :href="baseUrl+item" type="primary">{{getFilesName(item)}}</el-link>
                                            <el-link v-if="isview" class="fj-del" icon="el-icon-delete" @click="unsetFiles(details.ztzpfwj,index)"></el-link>
                                            <span v-if="!isview" style="color:#66b1ff;margin:0 10px 0 3px;font-weight: 700;">;</span>

                                        </div>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>

                    <div class="btns" v-if="isview">
                        <el-button type="primary" @click="qqsubmit()">保存</el-button>
                        <el-button @click="qqDrawer=false">取消</el-button>
                    </div>
                </div>
            </el-main>
        </el-drawer>
    </div>
</template>
<script>
import { exportData } from "../../js/util/export.js"; //导出表格

export default {
    name: "Home",
    components: {},
    data() {
        return {
            vloading: false,
            isview: false,
            hides: {
                jbxx: false,
                gq: false,
                kybg: false,
                cbsj: false,
                qzj: false,
                ztz: false,
            },
            baseUrl: "http://82.156.50.94:8002/",
            details: {},
            qqDrawer: false,
            tableHeight: 0, //表格高度
            activeIndex: true, //显隐高级搜索
            /**查询条件 begin*/
            xmmc: "", //项目名称
            xmnd: "", //项目年度
            checkedXmxz: [], //项目性质
            checkedXmlx: [], //项目类型
            /**查询条件 end*/
            xmxzList: [
                {
                    value: "续建项目",
                    label: "续建项目",
                    disabled: false,
                },
                {
                    value: "“十四五”新开工项目",
                    label: "“十四五”新开工项目",
                    disabled: false,
                },
                {
                    value: "“十四五”跨“十五五”",
                    label: "“十四五”跨“十五五”",
                    disabled: false,
                },
                {
                    value: "谋划项目",
                    label: "谋划项目",
                    disabled: false,
                },
            ],
            xmlxList: [
                {
                    value: "航道项目",
                    label: "航道项目",
                    disabled: false,
                },
                {
                    value: "港口项目",
                    label: "港口项目",
                    disabled: false,
                },
                {
                    value: "航电枢纽",
                    label: "航电枢纽",
                    disabled: false,
                },
                {
                    value: "支持系统",
                    label: "支持系统",
                    disabled: false,
                },
            ],
            currentPage: 1, //当前页
            pageSize: 30, //每页记录数
            isIndeterminateXmxz: true,
            isIndeterminateXmlx: true,
            tableData: [], //请求列表
            xmlx: "航道项目",
            total: 0, //请求记录数
        };
    },
    mounted() {
        var that = this;
        let height = this.$refs.init.offsetHeight;
        setTimeout(() => {
            that.tableHeight = document.body.offsetHeight - height - 145;
        }, 100);
        this.search();
    },
    methods: {
        unsetFiles(item, i) {
            item.splice(i, 1);
        },
        getFilesName(item) {
            var file = item.split("/");
            return file[file.length - 1];
        },
        fileSubmit(e, type) {
            this.vloading = true;
            let file = e.target.files;
            let form = new FormData();
            let arrs = [];
            file.forEach((element) => {
                form.append("fileInput", element);
                arrs.push(element.name);
            });
            this.http.post("/api/Plan_water_early/upload", form).then((res) => {
                console.log(res, 123);
                this.$message(res.message);
                if (res.status) {
                    arrs.forEach((v) => {
                        this.details[type].push(res.data + v);
                    });
                }
                this.vloading = false;
            });
        },
        qqsubmit() {
            var url = "/api/Plan_water_early/AddEarly";
            if (this.details.id != "00000000-0000-0000-0000-000000000000") {
                url = "/api/Plan_water_early/UpdateEarly";
            }
            var postdata = JSON.parse(JSON.stringify(this.details));
            var arr = [
                "kybgwj",
                "kybbcswj",
                "kybbpfwj",
                "cbsjwj",
                "cbsjpfwj",
                "ztzwj",
                "ztzpfwj",
            ];
            arr.forEach((v) => {
                postdata[v] = postdata[v] ? postdata[v].join(";") : "";
            });
            postdata.qzjjsyd = postdata.qzjjsyd ? "是" : "";
            postdata.qzjhjyx = postdata.qzjhjyx ? "是" : "";
            postdata.qzjqtsj = postdata.qzjqtsj ? "是" : "";
            postdata.qzjthlz = postdata.qzjthlz ? "是" : "";
            console.log(postdata);
            this.http
                .post(url, postdata, "正在请求数据，请稍候...")
                .then((res) => {
                    this.$message(res.message);
                    this.search();
                    this.qqDrawer = false;
                });
        },
        openDetailsView(row) {
            this.openDetailsEdit(row);
            this.isview = false;
        },
        openDetailsEdit(item) {
            this.isview = true;
            // this.http
            //     .post(
            //         "/api/Plan_water_early/getItem?id=" + item.id,
            //         {},
            //         "正在请求数据，请稍候..."
            //     )
            //     .then((res) => {
            //         console.log(res);
            //     });
            this.hides = {
                jbxx: false,
                gq: false,
                kybg: false,
                cbsj: false,
                qzj: false,
                ztz: false,
            };
            this.details = JSON.parse(JSON.stringify(item));
            var arr = [
                "kybgwj",
                "kybbcswj",
                "kybbpfwj",
                "cbsjwj",
                "cbsjpfwj",
                "ztzwj",
                "ztzpfwj",
            ];
            arr.forEach((v) => {
                this.details[v] = this.details[v]
                    ? this.details[v].split(";")
                    : [];
            });
            this.details.qzjjsyd = this.details.qzjjsyd == "是" ? true : false;
            this.details.qzjhjyx = this.details.qzjhjyx == "是" ? true : false;
            this.details.qzjqtsj = this.details.qzjqtsj == "是" ? true : false;
            this.details.qzjthlz = this.details.qzjthlz == "是" ? true : false;

            console.log(this.details);

            this.qqDrawer = true;
        },
        exportData() {
            exportData("Plan_water", this.postData());
        },
        //查询
        search() {
            this.http
                .post(
                    "/api/Plan_water_early/getList",
                    this.postData(),
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    if (res.status == 0) {
                        this.total = res.total;
                        this.tableData = res.rows;
                        console.log(this.tableData);
                    }
                });
        },
        postData() {
            //项目名称
            var query_xmmc = {
                Name: "XMMC",
                Value: this.xmmc,
                DisplayType: "like",
            };
            //项目年度
            var query_xmnd = {
                Name: "XMND",
                Value: this.xmnd,
                DisplayType: "Equal",
            };

            //项目性质
            var xmxzArray = [];
            this.checkedXmxz.forEach((element) => {
                xmxzArray.push(element);
            });
            var query_xmxz = {
                Name: "XMXZ",
                Value: xmxzArray.join(","),
                DisplayType: "checkbox",
            };
            //项目类型
            var query_xmlx = {
                Name: "XMLX",
                Value: this.checkedXmlx.join(","),
                DisplayType: "checkbox",
            };

            var postData = {
                page: this.currentPage,
                rows: this.pageSize,
                wheres: JSON.stringify([
                    query_xmmc,
                    query_xmnd,
                    query_xmxz,
                    query_xmlx,
                ]),
            };
            return postData;
        },
        clearCondition() {
            this.xmmc = "";
            this.xmnd = "";
            this.checkedXmxz = [];
            this.checkedXmlx = [];
        },
        handleCheckAllXmxzChange(val) {
            this.checkedXmxz = val ? this.xmxzList : [];
            this.isIndeterminateXmxz = false;
        },
        handleCheckAllXmlxChange(val) {
            this.checkedXmlx = val ? this.xmlxList : [];
            this.isIndeterminateXmlx = false;
        },
        handleCheckedXmxzChange(value) {
            let checkedCount = value.length;
            this.checkAllXmxz = checkedCount === this.xmxzList.length;
            this.isIndeterminateXmxz =
                checkedCount > 0 && checkedCount < this.xmxzList.length;
        },
        handleCheckedXmlxChange(value) {
            let checkedCount = value.length;
            this.checkAllXmlx = checkedCount === this.xmlxList.length;
            this.isIndeterminateXmlx =
                checkedCount > 0 && checkedCount < this.xmlxList.length;
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.search();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.search();
        },
    },
    watch: {
        activeIndex(v) {
            if (v) {
                this.tableHeight = document.body.offsetHeight - 350;
            } else {
                this.tableHeight = document.body.offsetHeight - 260;
            }
        },
    },
};
</script>

<style lang="less" scoped>
* {
    user-select: none;
}
.flexs-m {
    display: flex;
    align-items: initial;
}
.mains {
    .title {
        margin-top: 20px;
        background: #f2f8fe;
        display: flex;
        align-content: center;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;
        i {
            line-height: 32px;
            margin-right: 12px;
            font-size: 18px;
        }
        span {
            display: block;
            width: 98px;
            height: 32px;
            background: #409eff;
            line-height: 32px;
            font-size: 16px;
            font-family: Segoe UI;
            color: #ffffff;
            font-weight: 600;
            text-align: center;
        }
    }
    .boxs {
        margin-top: 10px;
        font-size: 14px;
        font-family: Segoe UI;
        // transition: 0.5s ease;
        .name {
            width: 168px;
            height: 42px;
            background: #fafafa;
            text-align: center;
            line-height: 42px;
            font-weight: 600;
        }
        .value {
            width: calc(100% - 168px);
            padding: 0 10px;
            // height: 42px;
            line-height: 42px;
            font-weight: 400;
            box-sizing: border-box;
            color: #666666;
        }
        .name1 {
            width: 74px;
            label {
                width: 100%;
                padding: 0;
                height: 32px;
                line-height: 32px;
                font-size: 14px;
                font-family: Segoe UI;
                font-weight: 400;
                color: #ffffff;
                i {
                    font-style: normal !important;
                }
            }
        }
        .value1 {
            width: calc(100% - 168px);
            display: flex;
            height: auto;
            label {
                display: inline-block;
                vertical-align: baseline;
            }
            .files {
                display: block;
                // border: 1px solid #b3d8ff;
                // height: 36px;
                // line-height: 36px;
                border-radius: 4px;
                position: relative;
                top: 2px;
                .wj {
                    margin-bottom: 5px;
                    border: 1px solid #b3d8ff;
                    height: 30px;
                    vertical-align: middle;
                    line-height: 30px;
                    display: inline-block;
                    margin-left: 10px;
                    border-radius: 4px;
                    padding: 0 5px;
                    position: relative;
                    top: -2px;
                }
            }
        }
        .name3 {
            background: none;
        }
    }
    .boxshide {
        height: 0;
        overflow: hidden;
        margin-top: 0;
    }
    .btns {
        margin-top: 20px;
        text-align: center;
    }
    .fj-del {
        margin-left: 10px;
        color: #f00;
    }
}
.isviewinfo {
    .title {
        margin-top: 15px !important;
        border-bottom: 1px #ebeef5 solid;
    }
    .boxs {
        margin-top: 0 !important;
        box-sizing: border-box;
        border-right: 1px #ebeef5 solid;
    }
    .el-row {
        margin-bottom: 0 !important;
    }
    .name {
        border: 1px #ebeef5 solid;
        border-top: 0;
        box-sizing: border-box;
    }
    .value {
        border-bottom: 1px #ebeef5 solid;
        box-sizing: border-box;
        height: 42px;
        padding-left: 10px !important;
        line-height: 40px !important;
    }
    .value1 {
        padding-left: 0 !important;
    }
    .files {
        padding-left: 5px !important;
    }
    .wj {
        border: 0 !important;
        top: 0 !important;
        margin-left: 0 !important;
    }
    .name3 {
        border-left: 0;
        border-right: 0;
    }
}
</style>
<style lang="less">
.isviewinfo {
    input {
        padding-left: 0 !important;
    }
    .el-input__icon {
        display: none;
    }
}

.mains {
    .el-input__inner {
        height: 32px;
    }
    .el-link--inner {
        height: 27px;
        position: relative;
        top: -2px;
    }
    .el-input__inner[readonly] {
        color: #333;
        // background: #f00;
        border: 0;
    }
    .el-input__inner[readonly]::placeholder {
        color: #fff;
    }
}
</style>
